export const homePageEventCategories = {
    HEADER_SECTION: 'top nav bar',
    HERO_SECTION: 'hero image',
    CUSTOMER_SUPPORT_SECTION: 'customer support',
    BENEFITS_SECTION: 'benefits',
    TESTIMONIALS_SECTION: 'testimonials',
    HOW_IT_WORKS_SECTION: 'registration steps',
    MONEY_BACK_GUARANTEE_SECTION: 'money back guarantee',
    FAIRNESS_PRINCIPLE_SECTION: 'fairness principle',
    FAQ_SECTION: 'faq',
    CUSTOMER_SUPPORT_FOOTER_SECTION: 'sub footer',
    FOOTER_SECTION: 'footer',
    YOLO_LOGIN: 'vermieten_page',
    CANARY_HO: 'canary_ho_page',
    VERMIETEN_PAGE: 'vermieten_page',
}

export const registrationFormEventCategories = {
    FIELD_ERROR: 'field_error',
    FORM_ERROR: 'form_error',
    NEW_REG: 'new_reg',
    REGISTRATION_FORM: 'ho_registration_form',
}
